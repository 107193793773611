import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import useWindowDimensions from '../helpers/helper';
import { Link } from '../Links/HeaderLinks';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { width } = useWindowDimensions();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const NavStyles =
  {
    my: 2,
    color: 'rgb(85, 85, 85)',
    display: 'block',
    textTransform: 'none',
    // fontWeight: 'bold',
    fontSize: '12px',
    fontFamily: 'inherit',
    paddingRight: '25px',
    paddingLeft: '25px',
    "&:hover": {
      color: 'rgb(250, 194, 203)',
    },
  }
  const Logo = <>
    <svg onClick={(e) => {
      handleOpenNavMenu()
    }} preserveAspectRatio="xMidYMid meet" data-bbox="29.9 29.6 139.9 140" viewBox="29.9 29.6 139.9 140" height="25" width="25" style={{ minHeight: "25", minWidth: "25", marginLeft: '-18px' }} xmlns="http://www.w3.org/2000/svg" data-type="color" role="img" aria-labelledby="svgcid--4vyqn1f5eed5">
      <g>
        <path fill="#010203" d="M51.7 47.1c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z" data-color="1"></path>
        <path fill="#010203" d="M114.1 47.1c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z" data-color="1"></path>
        <path fill="#010203" d="M169.8 47.1c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z" data-color="1"></path>
        <path fill="#010203" d="M51.7 99.6c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z" data-color="1"></path>
        <path fill="#010203" d="M114.1 99.6c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z" data-color="1"></path>
        <path fill="#010203" d="M169.8 99.6c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z" data-color="1"></path>
        <path fill="#010203" d="M51.7 152.1c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z" data-color="1"></path>
        <path fill="#010203" d="M114.1 152.1c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z" data-color="1"></path>
        <path fill="#010203" d="M163.2 152.1c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z" data-color="1"></path>
      </g>
    </svg>
    <span style={{ fontFamily: 'Inter', fontSize: '15px', fontWeight: 'normal', color: 'rgb(42, 42, 42)', marginLeft: '10px', marginRight: width > 1200 ? '50px' : '' }}>
      Deeplink
    </span></>

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} style={{ color: 'black', backgroundColor: '#00000000' }} position="static">
        <Toolbar>
          <IconButton
            size='large'
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.color = 'transparent';
            }}
            sx={{
              mr: 2,
              "&:hover": {
                backgroundColor: "transparent"
              },
            }}
          >

            <div>
              {width > 930 ?
                <a href='https://www.deeplink.network/'
                  style={{ textDecoration: 'none', color: 'rgb(85, 85, 85)' }}
                  target="_blank"
                  rel="noopener noreferrer">
                  {Logo}
                </a>
                :
                Logo
              }
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/about" text="About" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/documentation" text="Documentation" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://github.com/Deeplink-Network" text="Code" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/career" text="Career" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/contact" text="Contact" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/cnidaria" text="Cnidaria" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/deepbrew" text="DeepBrew" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/clusters" text="Clusters" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/superclusters" text="SuperClusters" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/dkeepers" text="DKeepers" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/research" text="Research" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://www.deeplink.network/openrddao" text="Open R&D Dao" />
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link href="https://dg.deeplink.network/" text="Airdrop & Staking" />
                </MenuItem>
              </Menu>
            </div>
          </IconButton>
          <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/etax" target="_blank" rel="noopener noreferrer">
            <Button
              variant='outlined'
              sx={{
                textTransform: 'none',
                borderRadius: '105px',
                minWidth: width < 430 ? '50px' : '90px',
                fontSize: width < 430 ? '10px' : '14px',
                height: '30px',
                fontWeight: 'normal',
                fontFamily: 'inherit',
                color: '#3c53f4',
                borderColor: '#3c53f4',
                "&:hover": {
                  boxShadow: '0 0 6px 1px #5f5bcd'
                },
              }}
            >
              Eta X
            </Button>
          </a>
          <Box sx={{ fontFamily: "'Arial','Helvetica','sans-serif'", flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            <div className="dropdown" >
              <Button
                sx={NavStyles}
              >
                About
              </Button>
              <div className="dropdown-content" >
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/about" target="_blank" rel="noopener noreferrer">
                  About
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/documentation" target="_blank" rel="noopener noreferrer">
                  Documentation
                </a>
                <a style={{ textDecoration: 'none' }} href="https://github.com/Deeplink-Network" target="_blank" rel="noopener noreferrer">
                  Code
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/career" target="_blank" rel="noopener noreferrer">
                  Career
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/contact" target="_blank" rel="noopener noreferrer">
                  Contact
                </a>
              </div>
            </div>

            <div className="dropdown" >
              <Button
                sx={NavStyles}
              >
                Technology
              </Button>
              <div className="dropdown-content" >
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/etax" target="_blank" rel="noopener noreferrer">
                  Eta X
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/cnidaria" target="_blank" rel="noopener noreferrer">
                  Cnidaria
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/deepbrew" target="_blank" rel="noopener noreferrer">
                  DeepBrew
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/clusters" target="_blank" rel="noopener noreferrer">
                  Clusters
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/superclusters" target="_blank" rel="noopener noreferrer">
                  SuperClusters
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/dkeepers" target="_blank" rel="noopener noreferrer">
                  DKeepers
                </a>
              </div>
            </div>

            <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/research" target="_blank" rel="noopener noreferrer">
              <Button
                sx={NavStyles}
              >
                Research
              </Button>
            </a>

            <div className="dropdown" >
              <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/openrddao" target="_blank" rel="noopener noreferrer">
                <Button
                  sx={NavStyles}
                >
                  Open R&D Dao
                </Button>
              </a>
              <div className="dropdown-content" >
                <a style={{ textDecoration: 'none' }} href="https://www.notion.so/7d85f134bca44680a928ae3aee222242?v=ac7348fda7ae4592af71e530d3619331" target="_blank" rel="noopener noreferrer">
                  Progress
                </a>
                <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/accomplishments" target="_blank" rel="noopener noreferrer">
                  Accomplishment Report
                </a>
              </div>
            </div>
            <a style={{ textDecoration: 'none' }} href="https://dg.deeplink.network/" target="_blank" rel="noopener noreferrer">
              <Button sx={NavStyles}>
                Airdrop & Staking
              </Button>
            </a>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <a style={{ textDecoration: 'none' }} href="https://www.deeplink.network/verified-contributor" target="_blank" rel="noopener noreferrer">
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  borderRadius: '105px',
                  width: width < 430 ? '100px' : '190px',
                  fontSize: width < 430 ? '10px' : '14px',
                  height: '30px',
                  fontWeight: 'normal',
                  color: 'grey',
                  borderColor: 'black',
                  "&:hover": {
                    boxShadow: '0 0 6px 1px #5f5bcd'
                  },
                }}
              >
                Become a Contributor
              </Button>
            </a>
          </Box>

        </Toolbar>

      </AppBar>
    </Box>
  );
}
export default ResponsiveAppBar;
