import Typography from '@mui/material/Typography';
import React from 'react';

export const Link = ({ href, text }) => (
  <Typography textAlign="center" style={{ fontFamily: "'Arial','Helvetica','sans-serif'" }}>
    <a
      style={{ textDecoration: 'none', color: 'rgb(85, 85, 85)' }}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  </Typography>
);