import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import LineTo from "react-lineto";
import Numeral from "react-numeral";
import balancerIcon from "../assets/images/balancerLogo.png";
import curveIcon from "../assets/images/CurveLogo.jpeg";
import sushiswapIcon from "../assets/images/sushiswapIcon.png";
import uniswapIcon from "../assets/images/uniswapV2icon.jpg";
import useWindowDimensions from "../helpers/helper";

const RouteDetailGraph = (props) => {
  const { height, width } = useWindowDimensions();
  const [rerenderLines, setRerenderLines] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [renderCount, setRenderCount] = useState(0);

  // useEffect(() => {
  //   console.log(`Height: ${height} and width ${width}`);
  // }, [height, width]);

  useEffect(() => {
    // console.log("Use effect called", props.rerenderLines);
    let renderCount = 0;
    while (renderCount < 2) {
      setIsLoading(true);
      setTableData([]);
      renderCount++;
    }
  }, []);

  // console.log("Height: ", height);

  useEffect(() => {
    // console.log("Table data is: ", props.tableData);
    // const timer = setTimeout(() => {
    //   console.log("Hello Adrees bhai");
    // }, 3000);
    setTableData(props.tableData);
    setIsLoading(false);
    // return () => clearTimeout(timer);
  }, [tableData]);

  // console.log("Props in route detail graph: ", props);

  return (
    <>
      {!isLoading ? (
        <>
          <Grid container spacing={0} style={{ marginTop: "70px" }}>
            <Grid
              // className="A"
              item
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                textAlign: "center",
                // marginRight: "-100px",
                // border: "1px solid black",
              }}
            >
              <span className="A" style={{ marginTop: "20px" }}>
                {props.tokenA?.symbol}
              </span>
            </Grid>
            {props.maxSizedArray?.map((_, index) => {
              return (
                <React.Fragment key={index}>
                  {index === 0 ? (
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Grid container spacing={0}>
                        {props.tableData.map((row, i) => (
                          <Grid
                            // className={`a${index}b${i}`}
                            key={i}
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <div
                              className={`c${i}`}
                              style={{
                                background: "#FEFEFE",
                                fontFamily: "Inter",
                                padding: "10px",
                                paddingRight: "35px",
                                fontSize: "12px",
                                marginTop: "20px",
                                marginBottom: "0px",
                                height:
                                  width < 460
                                    ? "101px"
                                    : width < 761
                                    ? "83px"
                                    : "65px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Numeral
                                value={row.percent}
                                format={"0.0[000]"}
                              />
                              %<br></br>
                              {/* <span style={{ color: "#797979" }}>
                          (50%)
                        </span> */}
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid key={index} item xl={3} xs={3} sm={3} md={3} lg={3}>
                    <Grid container spacing={0}>
                      {props.tableData.map((_, i) => {
                        return (
                          <Grid
                            key={i}
                            item
                            xl={12}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            <Grid container spacing={0}>
                              <Grid
                                sx={{ margin: "0px" }}
                                // className={`a${index}b${i}`}
                                item
                                xl={9}
                                lg={9}
                                md={9}
                                sm={9}
                                xs={9}
                              >
                                {props.swapData[i].split(">").length >
                                index + 1 ? (
                                  <div
                                    className={`a${index}b${i}`}
                                    style={{
                                      background: "#FEFEFE",
                                      border: "0.3px solid #D0DDFF",
                                      boxShadow:
                                        "0px 4px 4px rgba(0, 0, 0, 0.05)",
                                      borderRadius: "10px",
                                      // marginBottom: "200px",
                                      fontFamily: "Inter",
                                      padding: "10px",
                                      paddingRight: "35px",
                                      fontSize: "12px",
                                      marginTop: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {props.swapData[i].split(">")[index]}
                                    {">"}
                                    {props.swapData[i].split(">")[index + 1]}
                                    <br></br>
                                    {/* <span style={{ color: "#797979" }}>
                                    (50%)
                                  </span> */}
                                    <Container
                                      sx={{
                                        display: {
                                          xs: "block",
                                          xl: "flex",
                                          lg: "flex",
                                          md: "flex",
                                          sm: "flex",
                                        },
                                        alignItem: "left",
                                        marginLeft: "0px !important",
                                        padding: "0px !important",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                        src={
                                          props.tableData[i][`swap_${index}`]
                                            .exchange === "Uniswap_V3"
                                            ? uniswapIcon
                                            : props.tableData[i][
                                                `swap_${index}`
                                              ].exchange === "Uniswap_V2"
                                            ? uniswapIcon
                                            : props.tableData[i][
                                                `swap_${index}`
                                              ].exchange === "Sushiswap_V2"
                                            ? sushiswapIcon
                                            : props.tableData[i][
                                                `swap_${index}`
                                              ].exchange === "Curve"
                                            ? curveIcon
                                            : props.tableData[i][
                                                `swap_${index}`
                                              ].exchange === "Balancer_V2"
                                            ? balancerIcon
                                            : null
                                        }
                                        alt="Uniswap Icon"
                                      />
                                      <div
                                        style={{
                                          fontFamily: "Inter",
                                          fontSize:
                                            width < 460 ? "8px" : "10px",
                                          color: "grey",
                                          marginTop: "10px",
                                          // display: "flex",
                                          // alignItems: "center",
                                          // justifyContent: "center",
                                          marginLeft: "3px",
                                        }}
                                      >
                                        (
                                        {
                                          props.tableData[i][`swap_${index}`]
                                            .exchange
                                        }
                                        )
                                      </div>
                                    </Container>
                                  </div>
                                ) : (
                                  <div
                                    className={`a${index}b${i}`}
                                    style={{
                                      background: "#FEFEFE",
                                      // border: "0.3px solid #D0DDFF",
                                      // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                                      // borderRadius: "10px",
                                      // marginBottom: "200px",
                                      fontFamily: "Inter",
                                      padding: "10px 0px 10px 0px",
                                      // paddingRight: "35px",
                                      fontSize: "12px",
                                      marginTop: "20px",
                                      height:
                                        width < 460
                                          ? "123px"
                                          : width < 761
                                          ? "83px"
                                          : "65px",
                                    }}
                                  >
                                    {/* <div> */}
                                    <hr
                                      style={{
                                        // border: "0.5px dotted #000000",
                                        borderTop: "2px dotted #000",
                                        color: "#000000",
                                        height: "1px",
                                        width: "100%",
                                        transform: "rotate(-0.1deg)",
                                      }}
                                    ></hr>
                                    {/* </div> */}
                                  </div>
                                )}
                              </Grid>
                              {index < props.maxSizedArray.length - 1 ? (
                                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                                  <div
                                    style={{
                                      background: "#FEFEFE",
                                      // border: "0.3px solid #D0DDFF",
                                      // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                                      // borderRadius: "10px",
                                      // marginBottom: "200px",
                                      fontFamily: "Inter",
                                      padding: "10px 0px 10px 0px",
                                      // paddingRight: "35px",
                                      fontSize: "12px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div>
                                      <hr
                                        style={{
                                          // border: "2px dotted #000000",
                                          borderTop: "2px dotted #000",
                                          color: "#000000",
                                          height: "1px",
                                          width: "100%",
                                          // transform: "rotate(-0.1deg)",
                                        }}
                                      ></hr>
                                    </div>
                                  </div>
                                </Grid>
                              ) : null}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid
              // className="B"
              item
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <span className="B" style={{ marginTop: "20px" }}>
                {props.tokenB.symbol}
              </span>
            </Grid>
          </Grid>
          {rerenderLines ? (
            <>
              {props.tableData.map((_, i) => {
                return (
                  <LineTo
                    key={i}
                    from="A"
                    to={`c${i}`}
                    fromAnchor="right"
                    toAnchor="left"
                    borderColor="#000000"
                    borderStyle="dashed"
                    // within="within"
                  />
                );
              })}
              {props.tableData.map((_, i) => {
                return (
                  <LineTo
                    key={i}
                    from="B"
                    to={`a${props.maxSizedArray.length - 1}b${i}`}
                    fromAnchor="left"
                    toAnchor="right"
                    borderColor="#000000"
                    borderStyle="dashed"
                    // within="within"
                  />
                );
              })}
            </>
          ) : (
            <div></div>
          )}
        </>
      ) : null}
    </>
  );
};

export default RouteDetailGraph;
