import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createTheme,
  Grid,
  Popover,
  ThemeProvider,
  Typography
} from "@mui/material";
import { Markup } from "interweave";
import * as React from "react";
import Table from "react-bootstrap/Table";
import CytoscapeComponent from "react-cytoscapejs";
import Numeral from "react-numeral";
import { stylesheet1 } from "../Graphs/GraphHelper/Styles";

const theme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          border: "0.1px solid #FF9900",
        },
      },
    },
  },
});

function RouteDetailTable(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // React.useEffect(() => {
  //   console.log("Props in detail table: ", props);
  // }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Table responsive style={{ marginBottom: "30px", marginTop: "40px" }}>
      <thead>
        <tr
          style={{
            background: "#FFFFFF",
            border: "0.6px solid #E5E5E5",
            borderRadius: "3px",
            fontSize: "14px",
            fontWeight: 400,
            fontStyle: "normal",
            fontFamily: "Inter",
            height: "40px",
          }}
        >
          <th colSpan={1}></th>
          <th style={{ fontWeight: 400 }}>Best Routes</th>
          <th style={{ fontWeight: 400 }}>Route</th>
          {/* <th style={{ fontWeight: 400 }}>Price Impact</th> */}
          <th style={{ fontWeight: 400 }}>Gas Fee</th>
          <th style={{ fontWeight: 400 }}>Best Price</th>
          <th style={{ fontWeight: 400 }}>Min Receive</th>
          <th style={{ fontWeight: 400 }}>Price Impact</th>
        </tr>
      </thead>
      <tbody>
        {props.tableData.map((row, index) => (
          <React.Fragment key={index}>
            <tr
              style={{ fontFamily: "Inter", fontSize: "10px", height: "50px" }}
            >
              <td>
                {" "}
                <div style={{ marginTop: "10px" }}>{index + 1}</div>
              </td>
              <td colSpan={1}>
                {/* {data1.map((path, index) => ( */}
                <div style={{ justifyContent: "left" }}>
                  <div
                    style={{
                      marginTop: "0px",
                      height: "30px",
                      justifyContent: "left",
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <CytoscapeComponent
                      elements={props.data1[index]?.nodes}
                      // cytoscapeOptions={{ wheelSensitivity: 0.1 }}
                      style={{
                        width: "90px",
                        height: "30px",
                        display: "flex",
                        alignItems: "start",
                        marginLeft: "-20px",
                      }}
                      layout={{ name: "grid" }}
                      stylesheet={stylesheet1}
                      // zoom={0}
                      panningEnabled={false}
                      userPanningEnabled={false}
                    // zoomingEnabled={true}
                    />
                    {props.brokenPools[index] ? (
                      <button
                        style={{
                          paddingTop: "18px",
                          marginLeft: "0px",
                          color: "#FF9900",
                          border: "none",
                          background: "#FFFFFF",
                        }}
                        onClick={handleClick}
                      >
                        {"!"}
                      </button>
                    ) : null}
                    <div>
                      <ThemeProvider theme={theme}>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          elevation={0}
                          PaperProps={{
                            style: {
                              border: "1px solid rgba(255, 153, 0, 0.2)",
                            },
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "350px",
                              padding: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                // p: 1,
                                fontWeight: "500",
                                fontFamily: "Inter",
                                fontSize: "12px",
                              }}
                            >
                              We have detected low activities on this pool!
                            </Typography>
                            <br></br>
                            <Typography
                              sx={{
                                // p: 1,
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                              }}
                            >
                              This could be a broken pool and you may not be
                              able to interact with it.
                            </Typography>
                          </div>
                        </Popover>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </td>
              <td
                style={{ paddingTop: "15px" }}
                onClick={() => {
                  props.handleClick(index);
                }}
              >
                <Typography sx={{ fontFamily: "Inter", fontSize: "10px" }}>
                  <Grid container>
                    <Grid item xs={8}>
                      {props.swapData[index]}
                    </Grid>
                    <Grid item xs={4}>
                      {props.tableData[index]?.expand ? (
                        <ExpandLessIcon fontSize="small" />
                      ) : (
                        <ExpandMoreIcon fontSize="small" />
                      )}
                    </Grid>
                  </Grid>
                </Typography>
              </td>
              {/* <td style={{ paddingTop: '15px' }}>Low</td> */}
              <td style={{ paddingTop: "15px" }}>
                <Numeral value={row[1].gas_fee} format={"0,0.0[000]"} />
              </td>
              <td style={{ paddingTop: "15px" }}>
                <Numeral value={row[1].price} format={"0,0.0[000]"} />
              </td>
              <td style={{ fontWeight: "bold", paddingTop: "15px" }}>
                <Numeral value={row[1].amount_out} format={"0,0.0[000]"} />{" "}
                {props.tokenB?.symbol}
              </td>
              <td style={{ paddingTop: "15px" }}>
                {row[1].swap_0.price_impact == 0 ? (
                  "0.0"
                ) : <Numeral
                  value={row[1].swap_0.price_impact}
                  format={"0.0[000]"}
                />}

                %
              </td>
            </tr>
            {props.tableData[index].expand ? (
              <tr>
                <td colSpan={2}></td>
                <td colSpan={6}>
                  <Typography sx={{ fontFamily: "Inter", fontSize: "10px" }}>
                    <Markup
                      content={props.tableData[index][1].swap_0.description1}
                    />
                  </Typography>
                  {props.tableData[index][1]?.swap_1 ? (
                    <Typography sx={{ fontFamily: "Inter", fontSize: "10px" }}>
                      <Markup
                        content={props.tableData[index][1].swap_1?.description1}
                      />
                    </Typography>
                  ) : null}
                  {props.tableData[index][1]?.swap_2 ? (
                    <Typography sx={{ fontFamily: "Inter", fontSize: "10px" }}>
                      <Markup
                        content={props.tableData[index][1].swap_2?.description1}
                      />
                    </Typography>
                  ) : null}
                  {props.tableData[index][1]?.swap_3 ? (
                    <Typography sx={{ fontFamily: "Inter", fontSize: "10px" }}>
                      <Markup
                        content={props.tableData[index][1].swap_3?.description1}
                      />
                    </Typography>
                  ) : null}
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
export default RouteDetailTable;
