import * as React from 'react';
import useWindowDimensions from '../helpers/helper';

function HomeBanner() {

    const { height, width } = useWindowDimensions();
    return (
        <div
            // style={{
            //     marginLeft: width >= 1600 ? "300px" : width >= 1500 && width < 1600 ? "200px" : width >= 1400 && width < 1500 ? "110px" : width >= 1300 && width < 1400 ? "50px" : width >= 1200 && width < 1300 ? "0px" : width >= 1100 && width < 1200 ? "200px" : width >= 1000 && width < 1100 ? "200px" : width >= 900 && width < 1000 ? "100px" : width >= 700 && width < 900 ? "10px" : width >= 300 && width < 700 ? "0px" : '300px',
            //     marginRight: width >= 1600 ? "400px" : width >= 1500 && width < 1600 ? "200px" : width >= 1400 && width < 1500 ? "110px" : width >= 1300 && width < 1400 ? "50px" : width >= 1200 && width < 1300 ? "0px" : width >= 1100 && width < 1200 ? "200px" : width >= 1000 && width < 1100 ? "200px" : width >= 900 && width < 1000 ? "100px" : width >= 700 && width < 900 ? "10px" : width >= 300 && width < 700 ? "0px" : '300px',
            // }}
        >
            <div
                style={{
                    display: "flex",
                }}
            >
                <img
                    style={{ marginLeft: '-10px', alignSelf: "center", height: '70px', marginTop: '15px', }}
                    src="https://static.wixstatic.com/media/7b17b3_ffb1565780154db4858a8456910745ef~mv2.png/v1/crop/x_43,y_12,w_494,h_221/fill/w_494,h_221,al_c,q_85,enc_auto/Screenshot%202022-11-24%20at%2012_55_19%20am.png"
                />
            </div>
            <div
                style={{
                    marginTop: "20px",
                    // marginLeft: '30px'
                }}
            >
                <span
                    style={{
                        lineHeight: '12px',
                        fontSize: "14px",
                        fontFamily: "Inter",
                        // fontStyle: 'normal'
                        fontWeight: 400,
                        marginBottom: '75px'
                    }}
                >
                    Eta X is an open-source initiative to build an agnostic price
                    discovery engine, smart order router (SOR) framework for
                    decentralised exchanges (DEXs),and the growing list of liquidity
                    sources in decentralised finance (DeFi).
                </span>
            </div>
        </div>
    );
}
export default HomeBanner;
