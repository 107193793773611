import { Container, Popover, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import CytoscapeComponent from "react-cytoscapejs";
import Numeral from "react-numeral";
import useWindowDimensions from "../helpers/helper";
import { stylesheet1 } from "./GraphHelper/Styles";

const theme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          border: "0.1px solid #FF9900",
        },
      },
    },
  },
});

function BestRoutes(props) {
  const graphColors = [
    "#FEB8B8",
    "#9AD485",
    "#4C94FF",
    "#FFF3AF",
    "#323232",
    "#32A232",
    "#A23232",
    "#F23232",
    "#3232A2",
    "#32F2A2",
    "#F0F8FF",
    "#FF7F50",
    "#B22222",
    "#FF69B4",
    "#FFFACD",
    "#008000",
    "#808000",
    "#7FFFD4",
    "#FFC0CB",
    "#FF00FF",
  ];

  const { height, width } = useWindowDimensions();
  const [graphZoom, setGraphZoom] = React.useState(0.3);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // React.useEffect(() => {
  //   console.log("Props in best Routes: ", props);
  // }, []);

  return (
    <Container
      style={
        {
          // height: "450px",
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          // flexDirection: 'column'
        }
      }
    >
      <h6
        style={{
          // textAlign: 'center',
          fontWeight: "normal",
          fontFamily: "Inter",
          marginTop: "20px",
        }}
      >
        Best Routes
      </h6>
      {props.data1.slice(0, 3).map((path, index) => (
        <div key={index} style={{ justifyContent: "left" }}>
          <div style={{ lineHeight: 1, paddingBottom: "10px" }}>
            <div
              style={{
                height: "40px",
                justifyContent: "left",
                display: "flex",
                alignItems: "start",
                position: "relative",
                paddingLeft: "0px",
              }}
            >
              <CytoscapeComponent
                elements={path.nodes}
                style={{
                  width: "100px",
                  height: "50px",
                  display: "flex",
                  marginLeft: "-20px",
                }}
                layout={{ name: "grid" }}
                stylesheet={stylesheet1}
                panningEnabled={false}
                userPanningEnabled={false}
              />
              {props.brokenPools[index] ? (
                <button
                  style={{
                    paddingTop: "18px",
                    marginLeft: "0px",
                    color: "#FF9900",
                    border: "none",
                    background: "#FFFFFF",
                  }}
                  onClick={handleClick}
                >
                  {"!"}
                </button>
              ) : null}
              <div>
                <ThemeProvider theme={theme}>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    elevation={0}
                    PaperProps={{
                      style: {
                        border: "1px solid rgba(255, 153, 0, 0.2)",
                      },
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "350px",
                        padding: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          // p: 1,
                          fontWeight: "500",
                          fontFamily: "Inter",
                          fontSize: "12px",
                        }}
                      >
                        We have detected low activities on this pool!
                      </Typography>
                      <br></br>
                      <Typography
                        sx={{
                          // p: 1,
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "300",
                        }}
                      >
                        This could be a broken pool and you may not be able to
                        interact with it.
                      </Typography>
                    </div>
                  </Popover>
                </ThemeProvider>
              </div>
            </div>
            <span
              style={{
                fontWeight: "bold",
                fontFamily: "Inter",
                fontSize: "12px",
              }}
            >
              {/* {props.tableData[index][1]?.amount_out.toFixed(2)}{" "} */}
              <Numeral
                value={props.tableData[index][1]?.amount_out}
                format={"0,0.0[000]"}
              />{" "}
              {props.tokenB?.symbol}
            </span>
            <br />
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "normal",
                color: "#323232",
              }}
            >
              {props.swapData[index]}
            </span>
            <br />
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "normal",
                color: "#323232",
              }}
            >
              Price Impact (
              <Numeral
                value={props.tableData[index][1]?.swap_0?.price_impact}
                format={"0,0.0[000]"}
              />
              %)
            </span>
          </div>
          {index < 2 ? <hr /> : null}
        </div>
      ))}
    </Container>
  );
}
export default BestRoutes;
