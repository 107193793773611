import { Checkbox, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import HomeBanner from "../../Components/Banner/HomeBanner";
import Footer from "../../Components/Footer/Footer";
import BestRoutes from "../../Components/Graphs/BestRoutes";
import PossibleRoutes from "../../Components/Graphs/PossibleRoutes";
import RouteDetailGraph from "../../Components/Graphs/RouteDetailGraph";
import ResponsiveAppBar from "../../Components/Headers/header";
import useWindowDimensions from "../../Components/helpers/helper";
import RouteDetailTable from "../../Components/Table/RouteDetailTable";
import { TokenCard } from "../../Components/TokenCard/card";

const Home = () => {
  const [tokenAValue, setTokenAValue] = React.useState("0");
  const [tokenBValue, setTokenBValue] = React.useState(0);
  const [tokenA, setTokenA] = React.useState(null);
  const [tokenB, setTokenB] = React.useState(null);
  const [data1, setData1] = React.useState();
  const [data2, setData2] = React.useState();
  const [tableData, setTableData] = React.useState();
  const [swapData, setSwapData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(0);
  const { height, width } = useWindowDimensions();
  const [maxSizedArray, setMaxedSizedArray] = React.useState();
  const [brokenPools, setBrokenPools] = React.useState([]);
  const [graphSwapData, setGraphSwapData] = React.useState();
  const [graphRouteData, setGraphRouteData] = React.useState();
  const graphColors = [
    "#FEB8B8",
    "#9AD485",
    "#4C94FF",
    "#FFF3AF",
    "#323232",
    "#32A232",
    "#A23232",
    "#F23232",
    "#3232A2",
    "#32F2A2",
    "#F0F8FF",
    "#FF7F50",
    "#B22222",
    "#FF69B4",
    "#FFFACD",
    "#008000",
    "#808000",
    "#7FFFD4",
    "#FFC0CB",
    "#FF00FF",
  ];

  const handleSetRouteDetailGraphData = (data) => {
    console.log("data: ", data);
    let tempSwapData = [];
    data.map((index) => {
      let count = 0;
      let tempString = "";
      for (let key in index) {
        if (key.includes("swap")) {
          if (count === 0) {
            tempString = `${index[key].input_token} > ${index[key].output_token}`;
          } else {
            tempString = tempString.concat(` > ${index[key].output_token}`);
          }
          let description = index[key].description;
          description = description.split("\n");

          for (let i = 0; i < description.length; i++) {
            // console.log("description", description[i].length);
            if (description[i].length !== 4)
              description[i] = description[i] + "<br>";
          }

          description = description.join("");
          index[key].description1 = description;
          count++;
        }
      }
      tempSwapData.push(tempString);
    });
    console.log("Graph swap data: ", tempSwapData);
    setGraphRouteData(data);
    setGraphSwapData(tempSwapData);

    //finding the max sized array
    let ind = 0;
    let sizeArray = 0;
    tempSwapData.map((data, index) => {
      if (data?.split(">").length > sizeArray) {
        ind = index;
        sizeArray = data.split(">").length;
      }
    });
    let tempArray = tempSwapData[ind]?.split(">");
    tempArray?.pop();
    setMaxedSizedArray(tempArray);
  };

  const handleSetTableData = (data) => {
    let tempSwapData = [];
    data.map((index) => {
      let count = 0;
      let tempString = "";
      for (let key in index[1]) {
        if (key.includes("swap")) {
          if (count === 0) {
            tempString = `${index[1][key].input_token} > ${index[1][key].output_token}`;
          } else {
            tempString = tempString.concat(` > ${index[1][key].output_token}`);
          }
          let description = index[1][key].description;
          description = description.split("\n");

          for (let i = 0; i < description.length; i++) {
            // console.log("description", description[i].length);
            if (description[i].length !== 4)
              description[i] = description[i] + "<br>";
          }

          description = description.join("");
          index[1][key].description1 = description;
          count++;
        }
      }
      tempSwapData.push(tempString);
    });
    setTableData(data);
    setSwapData(tempSwapData);

    //finding the max sized array
    // let ind = 0;
    // let sizeArray = 0;
    // tempSwapData.map((data, index) => {
    //   if (data.split(">").length > sizeArray) {
    //     ind = index;
    //     sizeArray = data.split(">").length;
    //   }
    // });
    // let tempArray = tempSwapData[ind].split(">");
    // tempArray.pop();
    // setMaxedSizedArray(tempArray);
  };

  function convertGraphsData(pathGraphData, poolGraphData) {
    //SETTING PATH GRAPH DATA

    let path = [];
    let node1 = [];
    let link1 = [];
    let brokenPool = [];
    pathGraphData.map((route, index) => {
      let nodes = [];
      // let brokenPool = [];
      // console.log("Route dangeours", route[1].swap_0.dangerous);
      brokenPool.push(route[1].swap_0.dangerous);
      route[1].path.map((node, count) => {
        // console.log("Node: ", node);
        if (count === 0) {
          nodes.unshift({
            data: {
              id: node,
              color: graphColors[index],
            },
          });

          //FOR EASY COMPARISON
          node1.push({
            id: node,
            color: graphColors[index],
          });
        } else {
          nodes.unshift({
            data: {
              id: node,
              color: graphColors[index],
            },
          });

          //NODES FOR EASY COMPARISON
          node1.push({
            id: node,
            color: graphColors[index],
          });

          //LINKS
          nodes.push({
            data: {
              source: route[1].path[count - 1],
              target: node,
              color: graphColors[index],
            },
          });

          //LINKS FOR EASY COMPARISON
          link1.push({
            source: route[1].path[count - 1],
            target: node,
            color: graphColors[index],
            width: 1,
          });
        }
      });
      path.push({ nodes });
    });
    // console.log("Path: ", path);
    // console.log("Node 1: ", node1);
    // console.log("Link 1: ", link1);
    setData1(path);
    setBrokenPools(brokenPool);

    //SETTING POOL GRAPH DATA

    let nodes = [];
    let links = [];

    for (const key in poolGraphData) {
      var index1 = nodes.findIndex((item) => item.id === key);
      var index2 = node1.findIndex((item) => {
        // console.log("Item: ", item.id);
        // console.log("Key: ", key);
        return item.id === key;
      });
      // console.log("Index 2: ", index2);
      // console.log("Color: ", node1[index2].color);

      if (index1 === -1) {
        nodes.push({
          data: {
            id: key,
            label: `${key.split("_")[0]}_${key.split("_")[1]}`,
            color: index2 === -1 ? "#8c9091" : node1[index2].color,
          },
        });
      }

      for (let i = 0; i < poolGraphData[key].length; i++) {
        var index = nodes.findIndex(
          (item) => item.id === poolGraphData[key][i]
        );
        var index2 = node1.findIndex(
          (item) => item.id === poolGraphData[key][i]
        );
        // console.log("Index 2: ", index2);

        if (index === -1) {
          nodes.push({
            data: {
              id: poolGraphData[key][i],
              label: `${poolGraphData[key][i].split("_")[0]}_${
                poolGraphData[key][i].split("_")[1]
              }`,
              color: index2 === -1 ? "#8c9091" : node1[index2].color,
            },
          });
        }

        if (key !== poolGraphData[key][i]) {
          var index3 = link1.findIndex(
            (item) =>
              item.source === key && item.target === poolGraphData[key][i]
          );
          // console.log("Index 3: ", index3);
          if (index3 !== -1) {
            // console.log(
            //   "Color: ",
            //   index3 === -1 ? "#8c9091" : link1[index3].color
            // );
          }
          links.push({
            data: {
              source: key,
              target: poolGraphData[key][i],
              color: index3 === -1 ? "#8c9091" : link1[index3].color,
              width: index3 === -1 ? 0.5 : 1,
              type: index3 === -1 ? "normal" : "special",
            },
          });
        }
      }
    }

    // console.log("Pool graph: ", [...nodes, ...links]);
    setData2([...nodes, ...links]);
  }

  const [state, setState] = React.useState(0);

  const handleClick = (index) => {
    const updatedState = tableData[index];
    if (updatedState.expand) {
      delete updatedState.expand;
      setState((pre) => {
        return pre + 1;
      });
    } else {
      updatedState.expand = true;
      setState((pre) => {
        return pre + 1;
      });
    }
  };

  React.useEffect(() => {
    // console.log("Width: ", width);
    setIsLoading(isLoading + 1);
  }, [width]);

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} lg={11} xl={10} md={12} sm={12}>
          <div>
            <SnackbarProvider>
              <Container>
                <ResponsiveAppBar />
              </Container>
              <br />
              <Container>
                <HomeBanner />
              </Container>
              <>
                <Container>
                  <Grid
                    container
                    spacing={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {data2 && data1 && swapData && tableData ? (
                      <Grid
                        item
                        xl={7.5}
                        lg={7.5}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          background: "#FFFFFF",
                          border: "0.6px solid #D0DDFF",
                          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                          borderRadius: "10px",
                          marginBottom: "45px",
                          marginTop: "100px",
                        }}
                      >
                        <Container>
                          <Grid container spacing={1}>
                            {/* POOL GRAPH */}
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                              <PossibleRoutes data2={data2} />
                            </Grid>

                            {/* PATH GRAPH */}
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                              <BestRoutes
                                data1={data1}
                                tableData={tableData}
                                tokenB={tokenB}
                                swapData={swapData}
                                brokenPools={brokenPools}
                              />
                            </Grid>
                          </Grid>
                        </Container>
                      </Grid>
                    ) : null}
                    <Grid item lg={4.5} xl={4.5} style={{ marginTop: "100px" }}>
                      <Container>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "-10px",
                            marginBottom: "50px",
                          }}
                        >
                          <TokenCard
                            tokenAValue={tokenAValue}
                            tokenBValue={tokenBValue}
                            setTokenAValue={setTokenAValue}
                            setTokenBValue={setTokenBValue}
                            tokenA={tokenA}
                            tokenB={tokenB}
                            setTokenA={setTokenA}
                            setTokenB={setTokenB}
                            setGraphsData={convertGraphsData}
                            setTableData={handleSetTableData}
                            setData1={setData1}
                            setData2={setData2}
                            handleSetRouteDetailGraphData={
                              handleSetRouteDetailGraphData
                            }
                          />
                        </div>
                      </Container>
                    </Grid>
                  </Grid>
                </Container>
                <Container style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "41px",
                      height: "23px",
                      background:
                        "linear-gradient(178.43deg, #FDAFFF 1.33%, rgba(84, 127, 211, 0) 138.9%)",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    New
                  </div>
                  <a
                    href="https://apis.deeplink.network/reference/order_router-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#323232", textDecoration: "none" }}
                  >
                    <span
                      style={{
                        marginLeft: "5px",
                        fontFamily: "Inter",
                        marginTop: "2px",
                        fontSize: "12px",
                      }}
                    >
                      Try similar queries in thousands using our REST APIs
                    </span>
                  </a>
                  <div
                    style={{
                      marginLeft: "170px",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "-10px",
                    }}
                  >
                    <Checkbox defaultChecked />
                    <Link
                      to="/communitySubmission"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <span style={{ color: "#323232" }}>
                        Community votes for adding more DEXs
                      </span>
                    </Link>
                  </div>
                </Container>
              </>
              {data1 &&
              data2 &&
              swapData &&
              tableData &&
              graphRouteData &&
              graphSwapData ? (
                <Container style={{ marginBottom: "200px", marginTop: "20px" }}>
                  <RouteDetailGraph
                    tokenA={tokenA}
                    maxSizedArray={maxSizedArray}
                    swapData={graphSwapData}
                    tokenB={tokenB}
                    tableData={graphRouteData}
                    rerenderLines={state}
                  />
                  <Grid
                    container
                    spacing={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "100px",
                    }}
                  >
                    <Grid item xs={12} sx={{ borderRadius: 10 }} lg={12} xl={12}>
                      <Container>
                        <RouteDetailTable
                          tableData={tableData}
                          tokenB={tokenB}
                          data1={data1}
                          handleClick={handleClick}
                          swapData={swapData}
                          brokenPools={brokenPools}
                        />
                      </Container>
                    </Grid>
                  </Grid>
                </Container>
              ) : null}
            </SnackbarProvider>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Home;
