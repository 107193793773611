import {
  Container,
  createTheme,
  Grid,
  TablePagination,
  ThemeProvider
} from "@mui/material";
import axios from "axios";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import HomeBanner from "../../Components/Banner/HomeBanner";
import ResponsiveAppBar from "../../Components/Headers/header";

const theme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          color: "blue",
          marginTop: "5px",
        },
        input: {
          color: "blue",
          marginTop: "-10px",
        },
        actions: {
          marginTop: "-10px",
        },
      },
    },
  },
});

const CommunitySubmissionDisplayAll = () => {
  const [userTableData, setUserTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    axios
      .get(`https://cs.deeplink.network/api/exchange/`)
      .then((response) => {
        console.log("Response from getting exchange: ", response);
        setUserTableData(response.data.exchange.exchanges);
      })
      .catch((err) => {
        console.log("Error from getting exchange: ", err);
      });
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "500px",
        }}
      >
        <Grid item xs={12} lg={11} xl={10} md={12} sm={12}>
          <div>
            <SnackbarProvider>
              <Container>
                <ResponsiveAppBar />
              </Container>
              <br />
              <Container>
                <HomeBanner />
              </Container>
              <>
                <Container>
                  <h2
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "normal",
                      marginTop: "70px",
                    }}
                  >
                    Community Submissions
                  </h2>

                  {/* USER TABLE */}
                  <h5
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "normal",
                      marginTop: "30px",
                    }}
                  >
                    User Inputs
                  </h5>
                  <Table
                    responsive
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  >
                    <thead>
                      <tr
                        style={{
                          fontStyle: "normal",
                          fontFamily: "Inter",
                          marginBottom: "20px",
                          fontSize: "15px",
                        }}
                      >
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          Time stamp
                        </th>
                        <th
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          User ID
                        </th>
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          DEX/pool
                        </th>
                        <th
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "normal",
                            marginRight: "0px",
                          }}
                        >
                          Contract address
                        </th>
                      </tr>
                    </thead>
                    {userTableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, key) => {
                        return (
                          <tbody key={key} style={{ marginBottom: "20px" }}>
                            <tr
                              style={{
                                fontFamily: "Inter",
                                height: "70px",
                              }}
                            >
                              <td
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                }}
                              >
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(row.timeStamp * 1000)}
                                {/* {row.timeStamp} */}
                              </td>
                              <td
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                  minWidth: "40px",
                                }}
                              >
                                {row.userId}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                }}
                              >
                                {row.exchangeName}
                              </td>
                              <td
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                  marginRight: "0px",
                                  width: "10px",
                                }}
                              >
                                {row.contractAddress}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </Table>
                  <ThemeProvider theme={theme}>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={userTableData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </ThemeProvider>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      marginBottom: "50px",
                    }}
                  >
                    User IDs are generated randomly and we never collect user
                    data
                  </span>
                </Container>
              </>
            </SnackbarProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunitySubmissionDisplayAll;
