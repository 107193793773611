import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField
} from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useSnackbar } from "notistack";
import * as React from "react";
import Numeral from "react-numeral";
import useWindowDimensions from "../helpers/helper";
import ExchangeSelectionModal from "../Modals/ExchangeSelectionModal";
import TokenSelectionModal from "../Modals/TokenSelectionModal";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: "Inter",
          fontWeight: "normal",
          marginTop: "4px",
          fontSize: "25px",
        },
        notchedOutline: {
          borderColor: "#6578F4",
          opacity: "25%",
          vectorEffect: "non-scaling-stroke",
          borderRadius: "5px",
        },
      },
    },
  },
});

export function TokenCard({
  tokenAValue,
  tokenBValue,
  setTokenAValue,
  setTokenBValue,
  tokenA,
  tokenB,
  setTokenA,
  setTokenB,
  setGraphsData,
  setTableData,
  setData1,
  setData2,
  handleSetRouteDetailGraphData,
}) {
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = React.useState(false);
  const [showExchangeModal, setShowExchangeModal] = React.useState(false);
  const [tokenAValueDefault, setTokenAValueDefault] = React.useState(0);
  const [queryParams, setQueryParams] = React.useState({
    sell_symbol: null,
    sell_ID: null,
    sell_amount: 0,
    buy_symbol: null,
    buy_ID: null,
    exchanges: [],
  });
  const [tokenType, setTokenType] = React.useState("");
  const [routes, setRoutes] = React.useState([]);
  const [slippage, setSlippage] = React.useState(0);
  const [minAmountReceived, setMinAmountReceived] = React.useState(0);
  const [gasFee, setGasFee] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [typeToNumber, setTypeToNumber] = React.useState(false);
  const [checkedDEXs, setCheckedDEXs] = React.useState([]);
  const [allDEXs, setAllDEXs] = React.useState([]);


  const getDEXs=async()=>{
    let DexList=await axios.get('/DEX_LIST');
    console.log("DexList",DexList.data);
    setCheckedDEXs(DexList.data)
    setAllDEXs(DexList.data)
  }
  const findParameters = React.useCallback(() => {
    // console.log("routesroutesroutes", routes);
    setSlippage(routes[0][1].swap_0.price_impact);
    setGasFee(routes[0][1].gas_fee);
    // console.log("routes[0][1].price", routes[0][1].price);
    setPrice(routes[0][1].price);
    setMinAmountReceived(routes[0][1].amount_out);
    // setTokenBValue(routes[0][1].amount_out);
    setTokenBValue(numberWithCommas(routes[0][1].amount_out.toFixed(4)));
    // console.log("tokenAValuetokenAValue", tokenAValue);
    setTypeToNumber(false);
    setTokenAValueDefault(tokenAValue);
    setTokenAValue(numberWithCommas(tokenAValue));
  }, [setTokenBValue, routes]);

  React.useEffect(() => {
    if (tokenA && tokenB) {
      setQueryParams({
        sell_symbol: tokenA.symbol,
        sell_ID: tokenA.address.toLowerCase(),
        sell_amount: Number(tokenAValue),
        buy_symbol: tokenB.symbol,
        buy_ID: tokenB.address.toLowerCase(),
        exchanges: checkedDEXs,
      });
    }
  }, [tokenA, tokenB, tokenAValue, checkedDEXs]);

  React.useEffect(() => {
    if (routes.length !== 0) {
      findParameters();
      setIsLoading(false);
    }
  }, [routes, findParameters]);
  React.useEffect(()=>{
    getDEXs()
  },[])

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const callOrderRouter = async (queryParams) => {
    let sellingAmount = tokenAValue.includes(",")
      ? parseFloat(tokenAValue.toString().replace(/,/g, "")).toString()
      : tokenAValue;
    let url = `/order_router?sell_symbol=${queryParams.sell_symbol}&sell_ID=${queryParams.sell_ID}&sell_amount=${sellingAmount}&buy_symbol=${queryParams.buy_symbol}&buy_ID=${queryParams.buy_ID}&exchanges=${queryParams.exchanges}`;
    console.log("url",url);
    await axios
      .get(url)
      .then((response) => {
        console.log("Response from API: ", response);

        setRoutes(response.data.routes);
        setTableData(response.data.routes);

        let pathGraph = response.data.path_graph;
        let poolGraph = response.data.pool_graph;
        setGraphsData(response.data.routes, poolGraph);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
    let url1 = `/order_router_split?sell_symbol=${queryParams.sell_symbol}&sell_ID=${queryParams.sell_ID}&sell_amount=${sellingAmount}&buy_symbol=${queryParams.buy_symbol}&buy_ID=${queryParams.buy_ID}&exchanges=${queryParams.exchanges}`;
    await axios
      .get(url1)
      .then((response) => {
        console.log("Response from order router split: ", response);
        handleSetRouteDetailGraphData(response.data.routes.paths);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
  };

  const handleShowSelectionModal = () => {
    setShowModal(true);
  };

  const handleCloseSelectionModal = () => {
    setShowModal(false);
  };

  const handleOnSelectToken = (value) => {
    if (tokenType === "tokenA") {
      setTokenA(value);
      setSlippage(0);
      setMinAmountReceived(0);
      setGasFee(0);
      setTokenAValue("0");
      setTokenBValue(0);
    } else if (tokenType === "tokenB") {
      setTokenB(value);
    }
    handleCloseSelectionModal();
  };

  const onClickBestPriceButton = (event) => {
    event.preventDefault();
    // console.log("Event target value: ", event.target.value);
    let tokenAVal = Number(tokenAValue);
    if (
      tokenAVal === 0 ||
      tokenAVal === "0" ||
      tokenAVal === undefined ||
      tokenAVal === "undefined" ||
      tokenAVal === null ||
      tokenAVal === "null" ||
      tokenAVal === ""
    ) {
      let variant = "error";
      enqueueSnackbar("Enter value for selling token", { variant });
    } else if (
      tokenA === null ||
      tokenA === "null" ||
      tokenA === undefined ||
      tokenA === "undefined"
    ) {
      let variant = "error";
      enqueueSnackbar("Select selling token", { variant });
    } else if (
      tokenB === null ||
      tokenB === "null" ||
      tokenB === undefined ||
      tokenB === "undefined"
    ) {
      let variant = "error";
      enqueueSnackbar("Select purchasing token", { variant });
    } else {
      setIsLoading(true);
      setData1();
      setData2();
      setSlippage(0);
      setMinAmountReceived(0);
      setGasFee(0);
      setTokenBValue(0);
      callOrderRouter(queryParams);
    }
  };

  const handleChangeCheckbox = (event) => {
    // console.log(
    //   `Checked value: ${event.target.value} and check is: ${event.target.checked}`
    // );
    if (event.target.checked === false) {
      let temp = checkedDEXs.filter((dex) => dex !== event.target.value);
      setCheckedDEXs(temp);
    } else {
      setCheckedDEXs([...checkedDEXs, event.target.value]);
    }
    // console.log(`Checked dexes array: ${checkedDEXs}`);
  };

  return (
    <div>
      <Card
        sx={{
          maxWidth: 450,
          padding: 4,
          borderRadius: "10px",
        }}
        style={{
          background:
            "linear-gradient(180deg, #FEFEFE 0%, rgba(232, 247, 255, 0.596358) 27.86%, rgba(243, 224, 255, 0) 100%)",
          border: "0.6px solid #D0DDFF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))",
        }}
        elevation={0}
      >
        <h5
          style={{
            fontFamily: "Inter",
            fontWeight: "normal",
            float: "right",
          }}
        >
          v3
        </h5>
        <h5
          style={{
            fontFamily: "Inter",
            fontWeight: "normal",
            marginBottom: "40px",
          }}
        >
          Exchange
        </h5>
        <br></br>
        <span
          style={{
            fontSize: "11px",
            fontFamily: "Inter",
          }}
        >
          You Sell
        </span>
        <ThemeProvider theme={theme}>
          <TextField
            style={{
              fontWeight: "lighter",
              borderRadius: "5px",
              background: "#FFFFFF",
            }}
            sx={{
              borderRadius: "5px",
              marginTop: "5px",
              "& fieldset": {},
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            fullWidth
            size="small"
            type={typeToNumber ? "number" : ""}
            value={tokenAValue}
            onChange={(e) => {
              setTypeToNumber(true);
              // setTokenAValue(tokenAValueDefault);
              let value = e.target.value.includes(",")
                ? parseFloat(
                    e.target.value.toString().replace(/,/g, "")
                  ).toString()
                : e.target.value;
              if (value >= 0) {
                const regex = /^\d+(\.\d{1,4})?$/;
                let valid = regex.test(value);
                if (valid || value === "") {
                  setTokenAValue(value.replace(/^0+(?!\.|$)/, ""));
                  // console.log("Type of token a value: ", typeof tokenAValue);
                }
              }
              setSlippage(0);
              setMinAmountReceived(0);
              setGasFee(0);
              setTokenBValue(0);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => {
                    setTokenType("tokenA");
                    handleShowSelectionModal();
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      borderRadius: "10px",
                      width: tokenA !== null ? "100px" : "",
                      marginTop: tokenA !== null ? "5px" : "",
                    }}
                    sx={{
                      borderColor: "#BEBEBE",
                      border: "none",
                      "&:hover": {
                        backgroundColor: "transparent",
                        border: "none",
                      },
                    }}
                  >
                    {tokenA !== null ? (
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            height="20px"
                            style={{
                              float: "left",
                              marginTop: "2px",
                              marginBottom: "0px",
                              marginRight: "0px",
                            }}
                            src={tokenA?.logoURI}
                            alt="token1"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          style={{
                            float: "right",
                            marginLeft: "0px",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter",
                              color: "black",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                marginRight: "4px",
                                paddingLeft: "5px",
                              }}
                            >
                              {tokenA?.symbol}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="25"
                              fill="currentColor"
                              className="bi bi-chevron-down"
                              viewBox="0 0 14 20"
                            >
                              {" "}
                              <path
                                fillRule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                              />{" "}
                            </svg>
                          </span>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        color="black"
                        style={{ margin: "8px 10px 5px 0px", fontSize: "14px" }}
                        gutterBottom
                      >
                        Select a Token{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="25"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 14 20"
                        >
                          {" "}
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />{" "}
                        </svg>
                      </Typography>
                    )}
                  </Button>
                </InputAdornment>
              ),
              style: { fontSize: 30 },
            }}
          />
        </ThemeProvider>
        {/* </Paper> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <SwapVertIcon fontSize="medium" style={{ color: "grey" }} />
        </div>
        <span
          style={{
            fontSize: "11px",
          }}
        >
          You Buy
        </span>
        <ThemeProvider theme={theme}>
          <TextField
            inputProps={{
              readOnly: Boolean(true),
              disabled: Boolean(true),
            }}
            style={{
              fontWeight: "lighter",
              borderRadius: "5px",
              background: "#FFFFFF",
            }}
            sx={{
              marginTop: "5px",
              "& fieldset": {},
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: "right",
                },
              },
            }}
            size="small"
            fullWidth
            // type="number"
            value={tokenBValue}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => {
                    setTokenType("tokenB");
                    handleShowSelectionModal();
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      borderRadius: "10px",
                      width: tokenB !== null ? "100px" : "",
                      marginTop: tokenB !== null ? "5px" : "",
                    }}
                    sx={{
                      borderColor: "#BEBEBE",
                      border: "none",
                      "&:hover": {
                        backgroundColor: "transparent",
                        border: "none",
                      },
                    }}
                  >
                    {tokenB !== null ? (
                      <Grid container>
                        <Grid item xs={3}>
                          <img
                            height="20px"
                            style={{
                              float: "left",
                              marginTop: "2px",
                              marginBottom: "0px",
                              marginRight: "0px",
                            }}
                            src={tokenB?.logoURI}
                            alt="token2"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          style={{
                            float: "right",
                            marginLeft: "0px",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Inter",
                              color: "black",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "normal",
                                marginRight: "4px",
                                paddingLeft: "5px",
                              }}
                            >
                              {tokenB?.symbol}
                            </span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="25"
                              fill="currentColor"
                              className="bi bi-chevron-down"
                              viewBox="0 0 14 20"
                            >
                              {" "}
                              <path
                                fillRule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                              />{" "}
                            </svg>
                          </span>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        color="black"
                        style={{ margin: "8px 10px 5px 0px", fontSize: "14px" }}
                        gutterBottom
                      >
                        Select a Token{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="25"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 14 20"
                        >
                          {" "}
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />{" "}
                        </svg>
                      </Typography>
                    )}
                  </Button>
                </InputAdornment>
              ),
              style: { fontSize: 30 },
            }}
          />
        </ThemeProvider>
        <div
          style={{
            marginTop: "15px",
            textColor: "#323232",
            fontFamily: "Inter",
            fontWeight: "normal",
            fontSize: "15px",
          }}
        >
          <FormGroup row>
            {allDEXs.map((dex)=>{
             return <FormControlLabel
              sx={{ fontSize: "12px" }}
              value={dex}
              control={
                <Checkbox
                  defaultChecked
                  onChange={handleChangeCheckbox}
                  size="small"
                />
              }
              label={dex}
            />
            })}
          </FormGroup>
          <Button
            style={{
              color: "grey",
              textTransform: "unset",
              float: "right",
            }}
            onClick={() => {
              setShowExchangeModal(true);
            }}
          >
            + Add New
          </Button>
        </div>
        <div style={{ marginBottom: "30px" }}>
          {isLoading ? (
            <Button
              style={{
                marginTop: "40px",
                borderRadius: "7px",
                height: "48px",
                backgroundColor: "#354BF0",
                textTransform: "unset",
              }}
              fullWidth
              variant="contained"
              size="large"
              onClick={(event) => {
                let variant = "error";
                enqueueSnackbar("Fetching is in progress", { variant });
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Inter",
                  fontSize:
                    width < 337 ? "10px" : width < 390 ? "12px" : "15px",
                }}
              >
                <CircularProgress
                  color="inherit"
                  size={28}
                  style={{ margin: width < 337 ? "8px" : "10px" }}
                />{" "}
                Fetching Best Price ...
              </span>
            </Button>
          ) : (
            <Button
              style={{
                marginTop: "40px",
                borderRadius: "7px",
                height: "48px",
                backgroundColor: "#354BF0",
                textTransform: "unset",
              }}
              fullWidth
              variant="contained"
              size="large"
              onClick={(event) => {
                // console.log("Token A Value: ", tokenAValue);
                // setTokenAValue(
                //   tokenAValue.includes(",")
                //     ? parseFloat(
                //         tokenAValue.toString().replace(/,/g, "")
                //       ).toString()
                //     : tokenAValue
                // );
                console.log("Token A Default: ", tokenAValueDefault);
                onClickBestPriceButton(event);
              }}
            >
              <span style={{ fontSize: "15px", fontWeight: "normal" }}>
                Find best price
              </span>
            </Button>
          )}
        </div>
        <div
          style={{
            fontFamily: "Inter",
            fontWeight: "12px",
            color: "#323232",
            textAlign: "center",
            textDecoration: "underline",
            marginBottom: "40px",
          }}
        >
          <a
            href="https://apis.deeplink.network/reference/order_router-1"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#323232" }}
          >
            <span>Try similar queries in thousands using our REST APIs</span>
          </a>
        </div>
        <Grid
          style={{
            fontFamily: "Inter",
            fontSize: "12px",
          }}
          container
          // spacing={2}
        >
          <Grid item xs={8}>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Minimum Received
            </span>
          </Grid>
          <Grid item xs={4}>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {/* <Numeral value={tokenB} format={"0,0"} /> */}
              {tokenB?.symbol}{" "}
              <Numeral value={minAmountReceived} format={"0,0.0[000]"} />
            </span>
          </Grid>
          <Grid item xs={8}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              Price Impact
            </span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              {" "}
              {/* {parseFloat(slippage).toFixed(5)} {"%"} */}
              <Numeral value={slippage} format={"0,0.0[000]"} />%
            </span>
          </Grid>
          <Grid item xs={8}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              Gas fees
            </span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ fontFamily: "Inter", fontSize: "12px" }}>
              {/* {parseFloat(gasFee).toFixed(5)} (ETH) */}
              <Numeral value={gasFee} format={"0,0.0[000]"} /> (ETH)
            </span>
          </Grid>
        </Grid>
      </Card>
      <TokenSelectionModal
        show={showModal}
        handleClose={handleCloseSelectionModal}
        selectToken={handleOnSelectToken}
        // tokenType={tokenType}
        selectedToken={
          tokenType === "tokenA"
            ? tokenB
            : tokenType === "tokenB"
            ? tokenA
            : null
        }
      />
      <ExchangeSelectionModal
        show={showExchangeModal}
        handleClose={setShowExchangeModal}
      />
    </div>
  );
}
