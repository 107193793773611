export const stylesheet = [
    {
      selector: "node",
      style: {
        label: "data(label)",
        "background-color": "data(color)",
        padding: 0
      }
    },
    {
      selector: "edge",
      style: {
        width: 0.2,
        "line-color": "data(color)",
      }
    },
    // {
    //   selector: "edge:selected",
    //   style: {
    //     width: 10
    //   }
    // },
    {
      selector: "edge[type='special']",
      style: {
        width: 5
      }
    }
  ];


export const stylesheet1 = [
    {
      selector: "node",
      style: {
        "background-color": "data(color)",
        width: 10,
        height: 10
      }
    },
    {
      selector: "edge",
      style: {
        width: 0.5,
        "line-color": "data(color)"
      }
    }
  ];
  
