import { Box, Container } from "@mui/material";
import Slider from "@mui/material/Slider";
import * as React from "react";
import CytoscapeComponent from "react-cytoscapejs";
import useWindowDimensions from "../helpers/helper";
import { stylesheet } from "./GraphHelper/Styles";

function PossibleRoutes(props) {
  const { height, width } = useWindowDimensions();
  const [graphZoom, setGraphZoom] = React.useState(0.3);
  // const [graphWidth, setGraphWidth] = React.useState(0);
  const [graphData, setGraphData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    // console.log("Updated");
    setIsLoading(false);
    setGraphData({});
  }, [width]);

  React.useEffect(() => {
    setGraphData(props.data2);
    setIsLoading(true);
  }, [graphData]);

  return (
    <Container
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        // justifyContent: 'center',
        flexDirection: "column",
      }}
    >
      <h6
        style={{
          textAlign: "center",
          fontWeight: "normal",
          fontFamily: "Inter",
          marginTop: "20px",
          paddingRight: "15px",
        }}
      >
        Possible Routes
      </h6>
      {isLoading ? (
        <CytoscapeComponent
          elements={props.data2}
          cytoscapeOptions={{ wheelSensitivity: 0.1 }}
          style={{
            width:
              width < 385
                ? "200px"
                : width < 290
                ? "150px"
                : width < 180
                ? "100px"
                : width < 130
                ? "100px"
                : "500px",
            // width: `${graphWidth}px`,
            height: "500px",
            padding: "20px",
            // border: width > 690 ? "2px solid #FFDCA8" : "",
          }}
          layout={{ name: "circle" }}
          stylesheet={stylesheet}
          zoom={graphZoom}
          // panningEnabled={false}
          // userPanningEnabled={false}
          // zoomingEnabled={true}
        />
      ) : null}
      {isLoading ? (
        <Box
          sx={{
            marginBottom: "10px",
          }}
        >
          <Slider
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            sx={{
              color: "grey",
              width:
                width < 130 ? 70 : width < 180 ? 100 : width < 335 ? 100 : 200,
            }}
            size="small"
            // aria-label="Temperature"
            defaultValue={0.3}
            // getAriaValueText={valuetext}
            // valueLabelDisplay="auto"
            step={0.1}
            marks
            min={0}
            max={1}
            value={graphZoom}
            onChange={(e) => setGraphZoom(e.target.value)}
          />
        </Box>
      ) : null}
    </Container>
  );
}
export default PossibleRoutes;
