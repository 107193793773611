import { Grid } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import axios from "axios";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import HomeBanner from "../../Components/Banner/HomeBanner";
import ResponsiveAppBar from "../../Components/Headers/header";

const theme = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginTop: "5px",
        },
        input: {
          marginTop: "-10px",
        },
        actions: {
          marginTop: "-10px",
        },
      },
    },
  },
});

const CommunitySubmissions = () => {
  const navigate = useNavigate();
  const [rankingTableData, setRankingTableData] = useState([]);
  const [userTableData, setUserTableData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://cs.deeplink.network/api/exchange/`)
      .then((response) => {
        console.log("Response from getting exchange: ", response);
        let rankingTable = response.data.interests;
        rankingTable.sort((a, b) => {
          return b.count - a.count;
        });
        setUserTableData(response.data.exchange.exchanges);
        setRankingTableData(rankingTable);
      })
      .catch((err) => {
        console.log("Error from getting exchange: ", err);
      });
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [currentPage, setCurrenPage] = useState(1);

  const paginate = (number) => {
    setCurrenPage(number);
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "500px",
        }}
      >
        <Grid item xs={12} lg={11} xl={10} md={12} sm={12}>
          <div>
            <SnackbarProvider>
              <Container>
                <ResponsiveAppBar />
              </Container>
              <br />
              <Container>
                <HomeBanner />
              </Container>
              <>
                <Container>
                  <h2
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "normal",
                      marginTop: "70px",
                    }}
                  >
                    Community Submissions
                  </h2>
                  <h5
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "normal",
                      marginTop: "30px",
                    }}
                  >
                    Ranking
                  </h5>
                  <Table responsive style={{ marginTop: "50px" }}>
                    <thead>
                      <tr
                        style={{
                          fontStyle: "normal",
                          fontFamily: "Inter",
                          marginBottom: "20px",
                          fontSize: "15px",
                        }}
                      >
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          DEX/Pool
                        </th>
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          Contract Address
                        </th>
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          Interest
                        </th>
                      </tr>
                    </thead>
                    {rankingTableData.map((row, key) => {
                      return (
                        <tbody key={key} style={{ marginBottom: "20px" }}>
                          <tr
                            style={{
                              fontFamily: "Inter",
                              height: "70px",
                            }}
                          >
                            <td
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                paddingTop: "20px",
                              }}
                            >
                              {row.exchangeName}
                            </td>
                            <td
                              style={{
                                fontWeight: "normal",
                                fontSize: "12px",
                                paddingTop: "20px",
                              }}
                            >
                              {row.contractAddress}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                paddingTop: "20px",
                              }}
                            >
                              {row.count}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>

                  {/* USER TABLE */}
                  <h5
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "normal",
                      marginTop: "30px",
                    }}
                  >
                    User Inputs
                  </h5>
                  <Table
                    responsive
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  >
                    <thead>
                      <tr
                        style={{
                          fontStyle: "normal",
                          fontFamily: "Inter",
                          marginBottom: "20px",
                          fontSize: "15px",
                        }}
                      >
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          Time stamp
                        </th>
                        <th
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          User ID
                        </th>
                        <th
                          style={{ fontFamily: "Inter", fontWeight: "normal" }}
                        >
                          DEX/pool
                        </th>
                        <th
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "normal",
                            marginRight: "0px",
                          }}
                        >
                          Contract address
                        </th>
                      </tr>
                    </thead>
                    {userTableData
                      .slice(page * rowsPerPage, 5)
                      .map((row, key) => {
                        return (
                          <tbody key={key} style={{ marginBottom: "20px" }}>
                            <tr
                              style={{
                                fontFamily: "Inter",
                                height: "70px",
                              }}
                            >
                              <td
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                }}
                              >
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(row.timeStamp * 1000)}
                                {/* {row.timeStamp} */}
                              </td>
                              <td
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                  minWidth: "40px",
                                }}
                              >
                                {row.userId}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                }}
                              >
                                {row.exchangeName}
                              </td>
                              <td
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                  paddingTop: "20px",
                                  marginRight: "0px",
                                  width: "10px",
                                }}
                              >
                                {row.contractAddress}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                  </Table>
                  <div>
                    <button
                      style={{
                        float: "right",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        marginTop: "-15px",
                        textDecoration: "underline",
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                      onClick={() => {
                        navigate("/communitySubmission/displayAll");
                      }}
                    >
                      Display All
                    </button>
                  </div>
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                      marginBottom: "50px",
                    }}
                  >
                    User IDs are generated randomly and we never collect user
                    data
                  </span>
                </Container>
              </>
            </SnackbarProvider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunitySubmissions;
