import * as React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "../../App.css";
import CommunitySubmissionDisplayAll from "../Pages/CommunitySubmissionDisplayAll";
import CommunitySubmissions from "../Pages/CommunitySubmissions";
import Home from "../Pages/Home";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/communitySubmission"
          element={<CommunitySubmissions />}
        />
        <Route
          exact
          path="/communitySubmission/displayAll"
          element={<CommunitySubmissionDisplayAll />}
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
