import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Button, Grid, Paper } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

const Footer = () => {
  return (
    <Paper elevation={5} sx={{ marginTop: "300px" }}>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
            {/* <Container sx={{ marginTop: "50px" }}> */}
            <Grid container spacing={1} sx={{ marginTop: "50px" }}>
              <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={12}>
                <div>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "250",
                      borderRight: "1px solid black",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <svg
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="29.9 29.6 139.9 140"
                        viewBox="29.9 29.6 139.9 140"
                        height="50"
                        width="50"
                        style={{
                          minHeight: "25",
                          minWidth: "25",
                          // marginLeft: "-18px",
                          marginRight: "7px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="img"
                        aria-labelledby="svgcid--4vyqn1f5eed5"
                      >
                        <g>
                          <path
                            fill="#010203"
                            d="M51.7 47.1c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M114.1 47.1c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M169.8 47.1c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M51.7 99.6c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M114.1 99.6c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M169.8 99.6c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M51.7 152.1c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M114.1 152.1c0 9.665-7.835 17.5-17.5 17.5s-17.5-7.835-17.5-17.5 7.835-17.5 17.5-17.5 17.5 7.835 17.5 17.5z"
                            data-color="1"
                          ></path>
                          <path
                            fill="#010203"
                            d="M163.2 152.1c0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9 0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9z"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      <h3 style={{ fontWeight: "bold", marginTop: "5px" }}>
                        Deeplink
                      </h3>
                    </div>
                    <body
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "300",
                        fontSize: "14px",
                        marginTop: "15px",
                      }}
                    >
                      Introducing off-chain data, computation & machine learning
                      into blockchains.
                    </body>
                    <Button
                      variant="outlined"
                      color="inherit"
                      style={{
                        border: "1px solid #c9adc9",
                      }}
                      sx={{
                        borderRadius: "20px",
                        boxShadow: "0px 0px 7px 0px #c9adc9",
                        textTransform: "unset",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "300",
                        marginTop: "20px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href =
                          "https://www.deeplink.network/verified-contributor";
                      }}
                    >
                      <span style={{ padding: "5px 5px 5px 5px" }}>
                        Become a Contributor
                      </span>
                    </Button>
                    <a
                      href="https://www.deeplink.network/contact"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          marginTop: "30px",
                          fontWeight: "300",
                          fontFamily: "Inter",
                          fontSize: "14px",
                        }}
                      >
                        {/* <PeopleOutlineIcon sx={{ marginRight: "10px" }} /> */}
                        <svg
                          style={{ marginRight: "15px" }}
                          preserveAspectRatio="none"
                          data-bbox="23 36 154 128"
                          viewBox="23 36 154 128"
                          height="17"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="shape"
                          role="presentation"
                          aria-hidden="true"
                          aria-labelledby="svgcid-kijn0krjhig"
                        >
                          <title id="svgcid-kijn0krjhig"></title>
                          <g>
                            <path d="M137.182 146.667V160a4 4 0 0 1-8 0v-13.333c0-12.499-10.113-22.667-22.545-22.667H53.546C41.114 124 31 134.168 31 146.667V160a4 4 0 0 1-8 0v-13.333C23 129.757 36.703 116 53.546 116h53.091c16.842 0 30.545 13.757 30.545 30.667zm-87.636-80C49.546 49.757 63.248 36 80.091 36s30.546 13.757 30.546 30.667-13.703 30.666-30.546 30.666-30.545-13.757-30.545-30.666zm8 0c0 12.499 10.113 22.667 22.545 22.667s22.546-10.168 22.546-22.667S92.522 44 80.091 44 57.546 54.168 57.546 66.667zm96.549 50.328a4.001 4.001 0 0 0-4.876 2.868 3.999 3.999 0 0 0 2.868 4.876c9.95 2.581 16.905 11.599 16.913 21.928V160a4 4 0 0 0 8 0v-13.336c-.01-13.974-9.429-26.174-22.905-29.669zm-26.553-80.002a4.001 4.001 0 0 0-1.992 7.748c12.048 3.099 19.338 15.474 16.251 27.586-2.044 8.022-8.271 14.28-16.251 16.333a4 4 0 0 0 1.992 7.748c10.811-2.781 19.245-11.251 22.011-22.105 4.175-16.378-5.699-33.115-22.011-37.31z"></path>
                          </g>
                        </svg>
                        Community & Socials
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/career"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontWeight: "300",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          marginTop: "30px",
                        }}
                      >
                        <svg
                          style={{ marginRight: "15px" }}
                          preserveAspectRatio="xMidYMid meet"
                          data-bbox="29.5 29.501 141 141"
                          viewBox="29.5 29.501 141 141"
                          height="20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                          data-type="color"
                          role="presentation"
                          aria-hidden="true"
                          aria-labelledby="svgcid--r7wa4k-46hh9v"
                        >
                          <defs>
                            <style>
                              #comp-laxhojgf svg [data-color="1"]{" "}
                              {"fill: #2A2A2A;"}
                            </style>
                          </defs>
                          <title id="svgcid--r7wa4k-46hh9v"></title>
                          <g>
                            <path
                              d="M157.14 141.222c8.394-11.603 13.36-25.839 13.36-41.222a70.078 70.078 0 0 0-5.724-27.801 3.959 3.959 0 0 0-.415-.917C154.417 49.085 133.333 32.939 108.255 30a4.079 4.079 0 0 0-.619-.079 71.086 71.086 0 0 0-7.636-.42c-22.998 0-43.456 11.072-56.333 28.163a3.98 3.98 0 0 0-.807 1.115C34.466 70.381 29.5 84.617 29.5 100a70.087 70.087 0 0 0 5.724 27.802c.103.325.237.634.415.917 9.923 22.15 30.94 38.272 55.948 41.262.311.077.626.118.939.12 2.457.26 4.949.4 7.474.4 22.998 0 43.456-11.073 56.333-28.163.334-.331.611-.704.807-1.116zm-3.4-9.36L122.29 77.4h35.964A62.146 62.146 0 0 1 162.5 100a62.114 62.114 0 0 1-8.76 31.862zm-40.687-9.262H86.948L73.897 99.999 86.947 77.4h26.106l13.051 22.601-13.051 22.599zm41.413-53.2h-62.9l17.999-31.168c19.279 2.974 35.667 14.797 44.901 31.168zM100 37.5c.248 0 .492.016.739.019L69.278 92.001l-17.971-31.12C62.772 46.638 80.335 37.5 100 37.5zM46.26 68.138L77.71 122.6H41.746A62.146 62.146 0 0 1 37.5 100a62.114 62.114 0 0 1 8.76-31.862zm-.726 62.462h39.094l.015.001.015-.001h23.776l-17.999 31.168c-19.279-2.974-35.667-14.797-44.901-31.168zM100 162.5c-.248 0-.492-.016-.739-.019l31.462-54.482 17.971 31.12C137.228 153.362 119.665 162.5 100 162.5z"
                              fill="#000001"
                              data-color="1"
                            ></path>
                          </g>
                        </svg>
                        Career & Internships
                      </body>
                    </a>
                    <body
                      style={{
                        fontWeight: "300",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        marginTop: "30px",
                      }}
                    >
                      {/* <ScienceOutlinedIcon sx={{ marginRight: "10px" }} /> */}
                      <svg
                        style={{
                          marginRight: "15px",
                        }}
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="46.5 41 107.001 118"
                        viewBox="46.5 41 107.001 118"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="presentation"
                        aria-hidden="true"
                        aria-labelledby="svgcid--17e0ya-mkabav"
                      >
                        <defs>
                          <style>
                            #comp-laxhojhk svg [data-color="1"]{" "}
                            {"fill: #2A2A2A;"}
                          </style>
                        </defs>
                        <title id="svgcid--17e0ya-mkabav"></title>
                        <g>
                          <path
                            d="M134.234 159H65.768c-7.49 0-13.412-3.045-16.667-8.579-3.955-6.717-3.365-16.401 1.537-25.27l.119-.2L83.009 73.47V41h33.986v32.471l32.368 51.681c4.902 8.869 5.492 18.553 1.541 25.27-3.259 5.532-9.178 8.578-16.67 8.578zm-76.265-29.657c-3.363 6.142-3.989 12.72-1.597 16.786 1.725 2.932 4.886 4.418 9.396 4.418h68.466c4.51 0 7.674-1.487 9.396-4.418 2.392-4.067 1.765-10.645-1.597-16.786l-33.48-53.44V49.452H91.447v26.451l-33.478 53.44z"
                            fill="#000000"
                            data-color="1"
                          ></path>
                        </g>
                      </svg>
                      Hackathons
                    </body>
                    <div style={{ marginTop: "20px" }}>
                      {/* GITHUB */}
                      <a
                        href="https://github.com/Deeplink-Network"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="OIP"
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/8c60b8_52043b0b2eb54fb69d1faee30444651a~mv2.jpeg/v1/fill/w_20,h_20,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/8c60b8_52043b0b2eb54fb69d1faee30444651a~mv2.jpeg"
                        ></img>
                      </a>
                      {/* LINKED IN */}
                      <a
                        href="https://www.linkedin.com/company/deeplink-network"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="LinkedIn"
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/aa0402eb9ba2430d9d0620b59556efca.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/aa0402eb9ba2430d9d0620b59556efca.png"
                        ></img>
                      </a>
                      {/* MEDIUM */}
                      <a
                        href="https://medium.com/@deeplinknetwork"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="medium_1"
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/8c60b8_02fbe81ed3304b55a400fd7f2bbf5c90~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/8c60b8_02fbe81ed3304b55a400fd7f2bbf5c90~mv2.png"
                        ></img>
                      </a>
                      {/* DISCORD */}
                      <a
                        href="https://discord.com/invite/VSu4nTJQsP"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="Discord       "
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/11062b_39ef4249badb454ebeb165e744ca4f11~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_39ef4249badb454ebeb165e744ca4f11~mv2.png"
                        ></img>
                      </a>
                      {/* TWITTER */}
                      <a
                        href="https://twitter.com/DeeplinkNetwork"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="Twitter"
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/870f97661ed14a5bb2d96ecbddec0aed.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/870f97661ed14a5bb2d96ecbddec0aed.png"
                        ></img>
                      </a>
                      {/* OIP */}
                      <a
                        href="https://deeplinknetwork.gitbook.io/deeplink/overview/why-deeplink"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="OIP223"
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/8c60b8_5a5ed25506aa47d69303744ac6839717~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/8c60b8_5a5ed25506aa47d69303744ac6839717~mv2.png"
                        ></img>
                      </a>
                      {/* TELEGRAM */}
                      <a
                        href="https://t.me/+wfZ3VT6pf9NmMTI9"
                        style={{ pointer: "cursor", textDecoration: "none" }}
                      >
                        <img
                          alt="telegram-logo-black-and-white"
                          style={{
                            width: "20px",
                            height: "20px",
                            objectFit: "cover",
                            margin: "5px",
                          }}
                          fetchpriority="high"
                          src="https://static.wixstatic.com/media/8c60b8_41398beb78884be58efff74b6e3b6b15~mv2.png/v1/fill/w_20,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/8c60b8_41398beb78884be58efff74b6e3b6b15~mv2.png"
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={12}>
                <Container>
                  {/* <div style={{ borderLeft: "1px solid black" }} /> */}
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "300",
                      // borderLeft: "1px solid black",
                    }}
                  >
                    <h5>About</h5>
                    <a
                      href="https://www.deeplink.network/about"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        About
                      </body>
                    </a>
                    <a
                      href="https://github.com/Deeplink-Network"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Code
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Documentation
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/career"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Career
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/contact"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Contact
                      </body>
                    </a>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h5>Open R&D DAO</h5>
                    <a
                      href="https://www.notion.so/l3a/7d85f134bca44680a928ae3aee222242?v=ac7348fda7ae4592af71e530d3619331"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Progress Updates
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/accomplishments"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Accomplishment Report
                      </body>
                    </a>
                  </div>
                </Container>
              </Grid>
              <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={12}>
                <Container>
                  <div style={{ fontFamily: "Inter", fontWeight: "300" }}>
                    <h5>Technology</h5>
                    <a
                      href="https://www.deeplink.network/etax"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Eta X
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/cnidaria"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Cnidaria
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/deepbrew"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Deeplink v1
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/clusters"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Clusters
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/superclusters"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        SuperClusters
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/dkeepers"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        DKeepers
                      </body>
                    </a>
                    <br></br>
                    <br></br>
                    <h5>Airdrop & Staking</h5>
                    <a
                      href="https://dg.deeplink.network/#/contribute"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Airdrop & Staking
                      </body>
                    </a>
                    <a
                      href="https://dg.deeplink.network/#/contribute"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Stake DKeeper
                      </body>
                    </a>
                    <a
                      href="https://dg.deeplink.network/#/contribute"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Deep Airdrop
                      </body>
                    </a>
                  </div>
                </Container>
              </Grid>
              <Grid item xl={4.5} lg={4.5} md={4.5} sm={4.5} xs={12}>
                <Container>
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontStyle: "normal",
                    }}
                  >
                    <h5>Papers & Research</h5>
                    <a
                      href="https://www.deeplink.network/glossary"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Glossary of Terms
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/etax-scope"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Project Scope, Stakeholders, Roadmap & Deliverables
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/etax-data"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Eta X Data
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/dex-aggregators"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Comprehensive Guid to DEX Aggregators
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/sor"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        The Complete Guide Smart Order Routing
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/amm"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Automated Market Maker
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/pathfinding-and-route-optimizations"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Pathfinding Algorithms for DEX Aggregation and SOR
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/agent-based-reinforcement-learning"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Machine Learning Applications in DEX Aggregation and SOR
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/web3-metrics"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Evaluation of Confusing Web 3.0 Metrics
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/rainbow-dqn"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Deep Reinforcement Learning (Rainbow Algorithm, DQN
                        Algorithm)
                      </body>
                    </a>
                    <a
                      href="https://www.deeplink.network/dl-ipfs-eth"
                      style={{ pointer: "cursor", textDecoration: "none" }}
                    >
                      <body
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "300",
                          fontSize: "14px",
                        }}
                      >
                        Deep Learning + IPFS + Ethereum Blockchain in Practice
                      </body>
                    </a>
                  </div>
                  <a
                    href="https://www.deeplink.network/research"
                    style={{ pointer: "cursor", textDecoration: "none" }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        marginTop: "100px",
                        textTransform: "none",
                        fontWeight: "300",
                        fontSize: "14px",
                        color: "#000000",
                      }}
                    >
                      View All <ArrowRightAltIcon />
                    </div>
                  </a>
                </Container>
              </Grid>
            </Grid>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "300",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "60px",
              }}
            >
              {" "}
              Copyright 2022 • All Rights Reserved Deeplink Protocol
            </span>
            {/* </Container> */}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Footer;
