import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  TextField
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const ExchangeSelectionModal = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const [requesting, setRequesting] = useState(false);
  const [selectedExchangeName, setSelectedExchangeName] = useState("");
  const [selectedExchangeAddress, setSelectedExchangeAddress] = useState("");
  const [otherExchange, setOtherExchange] = useState("");
  const [exchangeList, setExchangeList] = useState([
    // {
    //   name: "Uniswap (V3)",
    //   address: "0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95",
    //   check: false,
    // },
    // {
    //   name: "Curve Finance",
    //   address: "0xB9fC157394Af804a3578134A6585C0dc9cc990d4",
    //   check: false,
    // },
    {
      name: "DODO (Ethereum)",
      address: "0x3A97247DF274a17C59A3bd12735ea3FcDFb49950",
      check: false,
    },
    // {
    //   name: "Balancer (V2)",
    //   address: "0x8E9aa87E45e92bad84D5F8DD1bff34Fb92637dE9",
    //   check: false,
    // },
    // {
    //   name: "Uniswap (V2)",
    //   address: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
    //   check: false,
    // },
    // {
    //   name: "SushiSwap",
    //   address: "0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac",
    //   check: false,
    // },
    {
      name: "KyberSwap Elastic (Ethereum)",
      address: "0x5F1dddbf348aC2fbe22a163e30F99F9ECE3DD50a",
      check: false,
    },
    {
      name: "Fraxswap (V2) (Ethereum)",
      address: "0x43eC799eAdd63848443E2347C49f5f52e8Fe0F6f",
      check: false,
    },
    {
      name: "ShibaSwap",
      address: "0x115934131916C8b277DD010Ee02de363c09d037c",
      check: false,
    },
    {
      name: "1inch Exchange",
      address: "0xbAF9A5d4b0052359326A6CDAb54BABAa3a3A9643",
      check: false,
    },
    {
      name: "Balancer",
      address: "0x9424B1412450D0f8Fc2255FAf6046b98213B76Bd",
      check: false,
    },
    {
      name: "DeFi Swap",
      address: "0x9DEB29c9a4c7A88a3C0257393b7f3335338D9A9D",
      check: false,
    },
    {
      name: "Bancor Network",
      address: "0xeEF417e1D5CC832e619ae18D2F140De2999dD4fB",
      check: false,
    },
    {
      name: "SaitaSwap",
      address: "0x19E5ebC005688466d11015e646Fa182621c1881e",
      check: false,
    },
    {
      name: "DOOAR (Ethereum)",
      address: "0x1e895bFe59E3A5103e8B7dA3897d1F2391476f3c",
      check: false,
    },
    {
      name: "RadioShack (Ethereum)",
      address: "0x91fAe1bc94A9793708fbc66aDcb59087C46dEe10",
      check: false,
    },
    {
      name: "KyberSwap Classic",
      address: "0x833e4083B7ae46CeA85695c4f7ed25CDAd8886dE",
      check: false,
    },
    {
      name: "SakeSwap.finance",
      address: "0x75e48C954594d64ef9613AeEF97Ad85370F13807",
      check: false,
    },
    {
      name: "DFX Finance (Ethereum)",
      address: "0x65071363538824F18A0261200843F03f7293757a",
      check: false,
    },
    {
      name: "Hashflow",
      address: "0xE8bc44AE4bA6EDDB88C8c087fD9b479Dff729850",
      check: false,
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const recaptchaValue = await recaptchaRef.current.getValue();
    console.log("Recaptcha Value: ", recaptchaValue);

    if (
      (selectedExchangeAddress === "" || selectedExchangeName === "") &&
      otherExchange === ""
    ) {
      let variant = "error";
      enqueueSnackbar(
        "Please select any exchange from list or enter a new one",
        { variant }
      );
    } else if (
      recaptchaValue === "" ||
      recaptchaValue === null ||
      recaptchaValue === "undefined" ||
      recaptchaValue === undefined
    ) {
      let variant = "error";
      enqueueSnackbar("Please verify yourself", { variant });
    } else {
      setRequesting(true);
      // console.log("First two character: ", otherExchange.slice(0, 2));
      if (selectedExchangeAddress === "" && selectedExchangeName === "") {
        if (otherExchange.slice(0, 2) === "0x") {
          // console.log("In the first confition");
          if (otherExchange.length !== 42) {
            let variant = "error";
            enqueueSnackbar("Please enter a valid address", { variant });
          } else {
            setSelectedExchangeAddress(otherExchange);
            await axios
              .post(`https://cs.deeplink.network/api/exchange/`, {
                contractAddress: otherExchange,
                exchangeName: " ",
              })
              .then((response) => {
                console.log(
                  "Response from requesting post of exchange: ",
                  response
                );
                if (response?.request?.status === 200) {
                  let variant = "success";
                  enqueueSnackbar("Your request have been submitted", {
                    variant,
                  });
                  props.handleClose(false);
                }
              })
              .catch((err) => {
                console.log("Error from requesting post of exchange: ", err);
                let variant = "error";
                enqueueSnackbar("An error occured while requesting", {
                  variant,
                });
              });
          }
        } else {
          // console.log("In current condition");
          setSelectedExchangeName(otherExchange);
          await axios
            .post(`https://cs.deeplink.network/api/exchange/`, {
              contractAddress: " ",
              exchangeName: otherExchange,
            })
            .then((response) => {
              console.log(
                "Response from requesting post of exchange: ",
                response
              );
              if (response?.request?.status === 200) {
                let variant = "success";
                enqueueSnackbar("Your request have been submitted", {
                  variant,
                });
                props.handleClose(false);
              }
            })
            .catch((err) => {
              console.log("Error from requesting post of exchange: ", err);
              let variant = "error";
              enqueueSnackbar("An error occured while requesting", { variant });
            });
        }
      } else {
        await axios
          .post(`https://cs.deeplink.network/api/exchange/`, {
            contractAddress: selectedExchangeAddress,
            exchangeName: selectedExchangeName,
          })
          .then((response) => {
            console.log(
              "Response from requesting post of exchange: ",
              response
            );
            if (response?.request?.status === 200) {
              let variant = "success";
              enqueueSnackbar("Your request have been submitted", { variant });
              props.handleClose(false);
            }
          })
          .catch((err) => {
            console.log("Error from requesting post of exchange: ", err);
            let variant = "error";
            enqueueSnackbar("An error occured while requesting", { variant });
          });
      }
      setRequesting(false);
    }
  };

  const handleChangeCheck = (event) => {
    let tempArrray = exchangeList.map((item) => {
      if (event.target.value === item.address) {
        if (item.check === true) {
          setSelectedExchangeAddress("");
          setSelectedExchangeName("");
          return { ...item, check: false };
        }
        setSelectedExchangeAddress(item.address);
        setSelectedExchangeName(item.name);
        return { ...item, check: true };
      } else if (item.check === true) {
        return { ...item, check: false };
      } else {
        return item;
      }
    });
    setExchangeList(tempArrray);
    setOtherExchange("");
  };

  const handleChangeOtherValue = (e) => {
    let tempArray = exchangeList.map((item) => {
      if (item.check === true) {
        return { ...item, check: false };
      }
      return item;
    });
    setExchangeList(tempArray);
    setSelectedExchangeAddress("");
    setSelectedExchangeName("");
    setOtherExchange(e.target.value);
  };

  return (
    <div>
      <Modal
        scrollable
        centered
        show={props.show}
        onHide={() => {
          props.handleClose(false);
        }}
      >
        <ModalHeader
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ModalTitle
            style={{
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            Select an exchange or add a liquidity pool
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Box>
            <List component="nav">
              <FormGroup>
                {exchangeList.map((item, key) => {
                  return (
                    <div
                      key={key}
                      style={{ marginTop: "20px", fontFamily: "Inter" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.check}
                            onChange={handleChangeCheck}
                          />
                        }
                        label={item.name}
                        value={item.address}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </List>
          </Box>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <h6
            style={{
              fontFamily: "Inter",
              float: "left",
            }}
          >
            Add Other Dex
          </h6>
          <TextField
            value={otherExchange}
            sx={{ borderRadius: "5px" }}
            fullWidth
            placeholder="Type a name or paste contract address"
            inputProps={{
              style: {
                height: "7px",
                borderRadius: "5px",
              },
            }}
            onChange={(e) => handleChangeOtherValue(e)}
          />
          <div style={{ marginTop: "12px", marginBottom: "12px" }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LeNNX0kAAAAAHnKzJHfgqpUbd1oFdVplsG1mUuw"
              theme="light"
              // onChange={handleSubmit}
            />
          </div>
          {requesting === true ? (
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#354BF0",
                fontStyle: "Inter",
                textTransform: "none",
                textAlign: "center",
                height: "50px",
              }}
            >
              <CircularProgress color="inherit" />
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#354BF0",
                fontStyle: "Inter",
                textTransform: "none",
                textAlign: "center",
                height: "50px",
              }}
              onClick={(e) => handleSubmit(e)}
            >
              <span>Submit</span>
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ExchangeSelectionModal;
